var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.classes,
      attrs: {
        autoHeight: _vm.watchedSettings.autoHeight,
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading,
        showExport: _vm.watchedSettings.showExport
      },
      on: { "export-click": _vm.toggleExport }
    },
    [
      !_vm.errorMessage
        ? _c(
            "div",
            {
              class: _vm.namespace("body"),
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _c(
                "div",
                { ref: "filter", class: _vm.namespace("filter-type") },
                [
                  _c(
                    "markets-ui-form",
                    [
                      _c(
                        "markets-ui-fieldset",
                        {
                          class: _vm.namespace("filter-type__group"),
                          attrs: { horizontal: "" }
                        },
                        [
                          _c("markets-ui-select", {
                            class: _vm.namespace("select"),
                            attrs: {
                              label: _vm.initedLabels.filter,
                              hiddenLabel: "",
                              skin: _vm.watchedSettings.skin,
                              dataModel: _vm.typeOption
                            },
                            on: { change: _vm.selectType }
                          }),
                          _vm._v(" "),
                          _vm.isRange
                            ? _c("markets-ui-select", {
                                class: [_vm.namespace("filter-type-select")],
                                attrs: {
                                  label: _vm.initedLabels.operator,
                                  hiddenLabel: "",
                                  skin: _vm.watchedSettings.skin,
                                  dataModel: _vm.operateOption
                                },
                                on: { change: _vm.selectOperate }
                              })
                            : _c("markets-ui-input", {
                                class: _vm.namespace("filter-type-input"),
                                attrs: {
                                  type: "number",
                                  step: "any",
                                  label: _vm.initedLabels.enterValue,
                                  hiddenLabel: "",
                                  disabled: _vm.isDisabled
                                },
                                model: {
                                  value: _vm.filterConfig.rangeStart,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.filterConfig,
                                      "rangeStart",
                                      $$v
                                    )
                                  },
                                  expression: "filterConfig.rangeStart"
                                }
                              }),
                          _vm._v(" "),
                          _c("markets-ui-input", {
                            class: _vm.namespace("filter-type-input"),
                            attrs: {
                              type: "number",
                              step: "any",
                              label: _vm.initedLabels.enterValue,
                              "hidden-label": "",
                              disabled: _vm.isDisabled,
                              size: "small"
                            },
                            model: {
                              value: _vm.filterConfig.rangeEnd,
                              callback: function($$v) {
                                _vm.$set(_vm.filterConfig, "rangeEnd", $$v)
                              },
                              expression: "filterConfig.rangeEnd"
                            }
                          }),
                          _vm._v(" "),
                          _c("markets-ui-button", {
                            class: _vm.namespace("button--primary"),
                            attrs: {
                              disabled: _vm.isDisabled,
                              variation: "primary",
                              text: _vm.initedLabels.go
                            },
                            on: {
                              click: function($event) {
                                return _vm.toFilterDataModel()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.namespace("filter-type__data-model-count") },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.initedLabels.total) +
                          " : " +
                          _vm._s(_vm.totalSize) +
                          "\n            "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [_vm.namespace("grid-container")],
                  style: _vm.watchedSettings.autoHeight
                    ? ""
                    : "height:calc(100% - " + _vm.filterHeight + "px);"
                },
                [
                  _c("markets-ui-grid", {
                    attrs: {
                      skin: _vm.watchedSettings.skin,
                      formatter: _vm.formatter,
                      option: _vm.gridOption,
                      columns: _vm.gridColumns,
                      rows: _vm.filterArray,
                      labels: _vm.gridLabels,
                      caption: _vm.initedLabels.timeSalescaption
                    },
                    on: { "page-changed": _vm.currentPageChanged }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("markets-ui-alert", {
                class: _vm.namespace("promt-alert"),
                attrs: {
                  skin: _vm.watchedSettings.skin,
                  message: _vm.notification.message,
                  visible: _vm.notification.visible,
                  dismissible: _vm.notification.dismissible,
                  dismissAutoly: _vm.notification.dismissAutoly,
                  type: _vm.notification.type
                },
                on: {
                  close: _vm.notificationClosed,
                  hide: _vm.notificationClosed
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "markets-ui-settings",
        {
          attrs: {
            slot: "settings",
            skin: _vm.watchedSettings.skin,
            "triggered-by": _vm.settingsPopoverTriggerId,
            visible: _vm.settingsPopoverVisible,
            title: _vm.popoverTitle
          },
          on: { hide: _vm.hideSettingsPopover },
          slot: "settings"
        },
        [
          _vm.exportPopoverSelected
            ? _c("markets-ui-list", {
                attrs: {
                  slot: "content",
                  size: "small",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNavSelectedList
                },
                on: { click: _vm.changeExportFileType },
                slot: "content"
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }