<template>
    <markets-ui-container
        :class="classes"
        :autoHeight="watchedSettings.autoHeight"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        :showExport="watchedSettings.showExport"
        @export-click="toggleExport"
    >
        <div slot="content" :class="namespace('body')" v-if="!errorMessage">
            <div :class="namespace('filter-type')" ref="filter">
                <markets-ui-form>
                    <markets-ui-fieldset
                        horizontal
                        :class="namespace('filter-type__group')"
                    >
                        <markets-ui-select
                            :label="initedLabels.filter"
                            hiddenLabel
                            :class="namespace('select')"
                            :skin="watchedSettings.skin"
                            :dataModel="typeOption"
                            @change="selectType"
                        />
                        <markets-ui-select
                            v-if="isRange"
                            :label="initedLabels.operator"
                            hiddenLabel
                            :class="[namespace('filter-type-select')]"
                            :skin="watchedSettings.skin"
                            :dataModel="operateOption"
                            @change="selectOperate"
                        />

                        <markets-ui-input
                            v-else
                            :class="namespace('filter-type-input')"
                            type="number"
                            step="any"
                            :label="initedLabels.enterValue"
                            hiddenLabel
                            :disabled="isDisabled"
                            v-model="filterConfig.rangeStart"
                        />
                        <markets-ui-input
                            :class="namespace('filter-type-input')"
                            type="number"
                            step="any"
                            :label="initedLabels.enterValue"
                            hidden-label
                            :disabled="isDisabled"
                            size="small"
                            v-model="filterConfig.rangeEnd"
                        />
                        <markets-ui-button
                            :class="namespace('button--primary')"
                            :disabled="isDisabled"
                            variation="primary"
                            :text="initedLabels.go"
                            @click="toFilterDataModel()"
                        />
                    </markets-ui-fieldset>
                </markets-ui-form>
                <div :class="namespace('filter-type__data-model-count')">
                    {{ initedLabels.total }} : {{ totalSize }}
                </div>
            </div>
            <div
                :class="[namespace('grid-container')]"
                :style="
                    watchedSettings.autoHeight
                        ? ''
                        : `height:calc(100% - ${filterHeight}px);`
                "
            >
                <markets-ui-grid
                    :skin="watchedSettings.skin"
                    :formatter="formatter"
                    :option="gridOption"
                    :columns="gridColumns"
                    :rows="filterArray"
                    :labels="gridLabels"
                    :caption="initedLabels.timeSalescaption"
                    @page-changed="currentPageChanged"
                >
                </markets-ui-grid>
            </div>
            <markets-ui-alert
                :class="namespace('promt-alert')"
                :skin="watchedSettings.skin"
                :message="notification.message"
                :visible="notification.visible"
                :dismissible="notification.dismissible"
                :dismissAutoly="notification.dismissAutoly"
                :type="notification.type"
                @close="notificationClosed"
                @hide="notificationClosed"
            >
            </markets-ui-alert>
        </div>
        <markets-ui-settings
            slot="settings"
            :skin="watchedSettings.skin"
            :triggered-by="settingsPopoverTriggerId"
            :visible="settingsPopoverVisible"
            :title="popoverTitle"
            @hide="hideSettingsPopover"
        >
            <markets-ui-list
                size="small"
                v-if="exportPopoverSelected"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNavSelectedList"
                @click="changeExportFileType"
                slot="content"
            >
            </markets-ui-list>
        </markets-ui-settings>
    </markets-ui-container>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import { dataPoints } from './metadata/datapoint';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-time-sales-ui',
    mixins: [mixins.componentUI],
    props: {
        dataModel: {
            type: Object,
            default() {
                return {
                    results: [],
                    security: null
                };
            }
        },
        showLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            filterHeight: 0,
            filterArray: [],
            filterConfig: {
                rangeStart: '',
                rangeEnd: '',
                type: 'noFilter',
                operateType: '>'
            },
            popoverTitle: 'Export',
            watchedSettings: {
                currentPage: 1
            },
            settingsPopoverVisible: false,
            settingsPopoverTriggerId: null,
            settingsPopoverNavSelected: undefined,
            exportPopoverSelected: false,
            settingsPopoverNavSelectedList: [
                {
                    id: 'excel',
                    name: 'Excel',
                    click: true
                }
            ],
            isFilter: false,
            notification: {
                visible: false,
                type: 'error',
                message: '',
                dismissible: true,
                dismissAutoly: 0
            },
            type: ['noFilter', 'price', 'size', 'priceRange', 'sizeRange'],
            operate: [
                'moreEqual',
                'moreThan',
                'lessEqual',
                'lessThan',
                'equalTo'
            ]
        };
    },
    computed: {
        classes() {
            const array = [this.namespace()];
            if (!this.watchedSettings.autoHeight) {
                array.push(this.namespace('body--scroll'));
            }
            return array;
        },
        gridLabels() {
            const labels = {};
            [
                'previousPage',
                'previousColumn',
                'nextPage',
                'nextColumn'
            ].forEach(key => {
                labels[key] = this.initedLabels[key];
            });
            return labels;
        },
        gridOption() {
            const option = {};
            [
                'scrollbarSize',
                'removable',
                'rowHeight',
                'autoHeight',
                'frozenColumn',
                'rowsPerPage',
                'rowsPerPageList',
                'showPageInfo',
                'showPageSelect',
                'layout',
                'currentPage'
            ].forEach(key => {
                if (typeof this.watchedSettings[key] !== 'undefined') {
                    option[key] = this.watchedSettings[key];
                }
            });
            return option;
        },
        gridColumns() {
            return dataPoints.map(dp => {
                return utils.extend(true, dp, {
                    name: this.initedLabels[dp.id],
                    sortable: this.watchedSettings.sortable
                });
            });
        },
        isDisabled() {
            return this.filterConfig.type === 'noFilter';
        },
        isRange() {
            return (
                this.filterConfig.type === 'price' ||
                this.filterConfig.type === 'size'
            );
        },
        totalSize() {
            return this.filterArray.length;
        },
        typeOption() {
            return this.type.map(item => {
                return {
                    id: item,
                    value: item,
                    name: this.initedLabels[item],
                    selected: item === this.filterConfig.type
                };
            });
        },
        operateOption() {
            return this.operate.map(item => {
                return {
                    id: item,
                    value: this.initedLabels[item],
                    name: this.initedLabels[item],
                    selected:
                        this.initedLabels[item] ===
                        this.filterConfig.operateType
                };
            });
        }
    },
    watch: {
        'dataModel.security': {
            handler() {
                this.resetConfig();
                this.changeSettings(this._getStoreSettings());
            }
        },
        'dataModel.results': {
            handler() {
                this._toFilterRange();
            },
            deep: true
        },
        'filterConfig.type'(val) {
            if (val === 'noFilter') {
                this.toFilterDataModel();
                this.resetConfig();
                this.$emit('filter-change', this.filterConfig);
                this.changeSettings(this._getStoreSettings());
            }
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('time-sales');
        this.filterConfig = {
            rangeStart: this.watchedSettings.rangeStart,
            rangeEnd: this.watchedSettings.rangeEnd,
            type: this.watchedSettings.type,
            operateType: this.watchedSettings.operateType
        };
    },
    methods: {
        _resizeObserverCallback({ entry }) {
            this.$nextTick(() => {
                this.filterHeight = utils.outerSizes(
                    this.$refs.filter,
                    true
                ).height;
            });
        },
        resetData() {
            this.filterConfig.rangeStart = '';
            this.filterConfig.rangeEnd = '';
            this.filterConfig.operateType = '>';
        },
        currentPageChanged(value) {
            this.watchedSettings.currentPage = value.page;
        },
        toFilterDataModel() {
            if (this.filterConfig.type === 'noFilter') {
                this.filterArray = this.dataModel.results;
                return false;
            }
            if (this.filterConfig.rangeEnd === '') {
                this.notification.visible = true;
                this.notification.message = this.initedLabels.notification;
                return false;
            }
            if (
                (this.filterConfig.type === 'sizeRange' ||
                    this.filterConfig.type === 'priceRange') &&
                this.filterConfig.rangeStart === ''
            ) {
                this.notification.visible = true;
                this.notification.message = this.initedLabels.notification;
                return false;
            }
            this.watchedSettings = utils.extend(
                this.watchedSettings,
                this.filterConfig
            );
            this.watchedSettings.currentPage = 1;
            this.$emit('filter-change', this.filterConfig);
            this.changeSettings(this._getStoreSettings());
            this._toFilterRange();
            return false;
        },
        _toFilterRange() {
            const dataModelList = this.dataModel.results;
            if (this.watchedSettings.type === 'noFilter') {
                this.filterArray = dataModelList;
                return;
            }
            if (
                this.watchedSettings.type === 'sizeRange' ||
                this.watchedSettings.type === 'priceRange'
            ) {
                const rangeType =
                    this.watchedSettings.type === 'sizeRange'
                        ? 'size'
                        : 'price';
                if (
                    parseFloat(this.watchedSettings.rangeStart) >=
                    parseFloat(this.watchedSettings.rangeEnd)
                ) {
                    this.filterArray = dataModelList.filter(item => {
                        return (
                            parseFloat(item[rangeType]) >=
                                parseFloat(this.watchedSettings.rangeEnd) &&
                            parseFloat(item[rangeType]) <=
                                parseFloat(this.watchedSettings.rangeStart)
                        );
                    });
                } else {
                    this.filterArray = dataModelList.filter(item => {
                        return (
                            parseFloat(item[rangeType]) >=
                                parseFloat(this.watchedSettings.rangeStart) &&
                            parseFloat(item[rangeType]) <=
                                parseFloat(this.watchedSettings.rangeEnd)
                        );
                    });
                }
            } else {
                const operateType = utils.find(this.operateOption, item => {
                    return item.value === this.watchedSettings.operateType;
                });
                this.filterArray = dataModelList.filter(item => {
                    return this._toCompareVal(
                        item[this.watchedSettings.type],
                        operateType.id
                    );
                });
            }
        },
        _toCompareVal(value, type) {
            let isFlag;
            const compareVal = this.watchedSettings.rangeEnd;
            switch (type) {
                case 'moreEqual':
                    isFlag = parseFloat(value) >= parseFloat(compareVal);
                    break;
                case 'moreThan':
                    isFlag = parseFloat(value) > parseFloat(compareVal);
                    break;
                case 'lessEqual':
                    isFlag = parseFloat(value) <= parseFloat(compareVal);
                    break;
                case 'lessThan':
                    isFlag = parseFloat(value) < parseFloat(compareVal);
                    break;
                case 'equalTo':
                    isFlag = parseFloat(value) === parseFloat(compareVal);
                    break;
                default:
                    break;
            }
            return isFlag;
        },
        toggleExport(e) {
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.exportPopoverSelected = !this.exportPopoverSelected;
            this.settingsPopoverTriggerId = utils.computeTriggerId(e.target);
        },
        exportData(fileType) {
            const columns = dataPoints.map(label => {
                return this.initedLabels[label.id];
            });
            const columnLabel = dataPoints.map(label => {
                return label.id;
            });
            const exportItem = {
                columnLabel: columnLabel.toString(),
                columns: columns.toString(),
                fileType: fileType,
                sheetName: `${this.initedLabels['title']}:${this.watchedSettings.symbol}.xls`
            };
            this.exportPopoverSelected = false;
            this.$emit(
                'export-click',
                exportItem,
                this.formatter,
                this.filterArray
            );
        },
        selectType(item) {
            this.filterConfig.type = item.value;
            this.resetData();
        },
        selectOperate(item) {
            this.filterConfig.operateType = item.value;
        },
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.exportPopoverSelected = false;
            this.$emit('settings-popover-hide');
        },
        changeExportFileType(item) {
            this.exportData(item.id);
            this.settingsPopoverVisible = false;
        },
        notificationClosed() {
            this.notification.visible = false;
            this.notification.message = '';
        },
        resetConfig() {
            this.watchedSettings.type = 'noFilter';
            this.watchedSettings.rangeStart = '';
            this.watchedSettings.rangeEnd = '';
            this.filterConfig.type = 'noFilter';
            this.filterConfig.rangeStart = '';
            this.filterConfig.rangeEnd = '';
        },
        _getStoreSettings() {
            const symbol = utils.isEmptyObject(this.dataModel.security)
                ? this.watchedSettings.symbol
                : this.getSavedSymbol(this.dataModel.security);
            if (symbol) {
                return {
                    symbol,
                    type: this.watchedSettings.type,
                    rangeStart: this.watchedSettings.rangeStart,
                    rangeEnd: this.watchedSettings.rangeEnd,
                    operateType: this.watchedSettings.operateType
                };
            } else {
                return null;
            }
        }
    }
};
</script>

<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-time-sales';
.#{$namespace} {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }
    &-select {
        margin-top: $mds-space-three-quarter-x;
        flex-basis: 140px;
    }
    &-body {
        min-width: 300px;
        &--scroll {
            overflow: auto;
        }
    }
    &-filter-type {
        label,
        button {
            flex: unset;
            flex-grow: 0;
            flex-shrink: 0;
        }
        &-input,
        &-select {
            margin: $mds-space-three-quarter-x $mds-space-three-quarter-x 0
                $mds-space-three-quarter-x;
            flex-basis: 80px;
        }
        &__data-model-count {
            align-self: center;
            margin: $mds-space-three-quarter-x;
            flex-grow: 0;
            flex-shrink: 0;
        }
        display: flex;
        justify-content: space-between;
    }

    &-promt-alert {
        width: 400px;
        height: 48px;
        position: absolute;
        left: 50%;
        top: 40px;
        margin-left: -200px;
    }
    &-button--primary {
        margin: $mds-space-three-quarter-x auto $mds-space-2-x
            $mds-space-three-quarter-x;
    }

    &.markets-ui-container__fixed-height {
        .#{$namespace}-body {
            height: 100%;
        }
        .#{$namespace}-grid-container {
            height: calc(100% - 45px);
            &__no-menu {
                height: 100%;
            }
        }
    }
    &.markets-ui-container__dark-gray {
        .#{$namespace}-filter-type {
            background: $mds-background-color-dark-gray;
            color: $mds-text-color-primary-on-dark;
            .#{$namespace}-button--primary {
                background: #0077cf;
                color: #fff;
            }
        }
        input[type='number'] {
            background: $mds-background-color-dark-gray;
            color: $mds-text-color-primary-on-dark;
        }
    }
}
</style>
