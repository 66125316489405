export const dataPoints = [
    {
        id: 'dateTime',
        minWidth: 150,
        maxWidth: 300,
        width: 220,
        dataType: 'dateTime'
    },
    {
        id: 'shortExchangeName',
        align: 'right'
    },
    {
        id: 'price',
        align: 'right',
        minWidth: 100,
        maxWidth: 150,
        dataType: 'number'
    },
    {
        id: 'size',
        align: 'right',
        dataType: 'int'
    }
];
