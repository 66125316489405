<template>
    <TIMESALESUI
        ref="UI"
        :skin="skin"
        :showLoading="showLoading"
        :dataModel="dataModel"
        :errorCode="error.code"
        :settings="settings"
        :labels="initedLabels"
        :formatter="formatter"
        @export-click="exportClick"
        @export-popover-hide="hideExportPopover"
        @settings-changed="changeSettings"
        @filter-change="changeFilter"
    >
    </TIMESALESUI>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import TIMESALESUI from './time-sales-ui';
import { dataPoints } from './metadata/datapoint';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-time-sales',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    props: ['symbol'],
    data() {
        return {
            defaultConfig: {
                settings: {
                    autoHeight: true,
                    symbol: '',
                    showHeader: false,
                    showBorder: false,
                    showExport: true,
                    updateLastAccessTime: true, //loop call will not extend session time, true is enable, false is disable;
                    languageId: 'en-US',
                    sortable: true,
                    skin: '',
                    count: 200,
                    rangeStart: '',
                    rangeEnd: '',
                    type: 'noFilter',
                    operateType: '>',
                    pollingTime: 5000,
                    frozenColumn: 0,
                    rowsPerPage: 20,
                    rowsPerPageList: [10, 20, 50],
                    showPageInfo: true,
                    showPageSelect: true,
                    layout: ''
                },
                labels: labels,
                format: {
                    integer: {
                        dataType: 'number',
                        useGrouping: true
                    },
                    number: {
                        dataType: 'number',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    },
                    dateTime: {
                        dataType: 'date',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        day: '2-digit',
                        month: 'numeric',
                        year: 'numeric',
                        hour12: true
                    }
                },
                intlNamespace: 'mwc-markets-time-sales'
            },
            dataModel: {
                results: [],
                security: null
            },
            showLoading: false
        };
    },
    watch: {
        symbol(val) {
            if (!val) {
                return;
            }
            this.params.security = val;
            delete this.params.st;
            delete this.params.sd;
            this.dataModel.results = [];
            this.changeSymbol();
        }
    },
    created() {
        this.params = {};
        this.count = null;
        this.pollingTimeout = null;
        this.pollingTime = 0;
        this.targetVisibility = false;
        this.initialized = false;
        this.changeType({
            symbol: this.settings.symbol,
            count: this.settings.count,
            pollingTime: this.settings.pollingTime
        });
    },
    computed: {
        initedLabels() {
            return this.mergeLabels(labels);
        },
        totalSize() {
            return this.dataModel.results.length;
        }
    },
    mounted() {
        utils.visibilityObserver.observe(
            this.$el,
            this._visibilityObserverCallback
        );
        if (!utils.isHidden(this.$el) && !this.initialized) {
            this.initialize();
        }
    },
    beforeDestroy() {
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
        this._stopPolling();
    },
    methods: {
        initialize() {
            this.initialized = true;
            this.changeSymbol();
        },
        _visibilityObserverCallback({ visibility }) {
            if (visibility) {
                if (!this.initialized) {
                    this.initialize();
                }
            } else {
                if (!this.targetVisibility) {
                    this._stopPolling();
                }
                this.targetVisibility = false;
            }
        },
        exposeMethods() {
            return ['toggleSettings', 'toggleExport'];
        },
        changeTargetElement() {
            this.targetVisibility = true;
        },
        changeSymbol() {
            this.acceptTicker(this.params.security);
            this.mktdata
                .securities([this.params.security])
                .then(securities => {
                    const security = securities[0];
                    // timesales require instrument
                    if (security.instrument) {
                        this.dataModel.security = security;
                        this.getTimeSalesData();
                    } else {
                        this.dataModel.security = null;
                        this._setError(this.ERROR_TYPES.INVALID_INVESTMENT);
                    }
                })
                .catch(() => {
                    this.dataModel.security = null;
                    this._stopPolling();
                    this._setError(this.ERROR_TYPES.INVALID_INVESTMENT);
                });
        },
        getTimeSalesData(isLooping) {
            if (!this.mktdata) {
                return;
            }
            if (!isLooping) {
                this.showLoading = true;
            }
            this.mktdata
                .timeSales(this.params)
                .then(resData => {
                    this.error = {};
                    const queryKey = resData.security;
                    const resDataArry = resData.results;
                    if (
                        queryKey === this.dataModel.security.queryKey &&
                        resDataArry &&
                        resDataArry.length > 0
                    ) {
                        this._startPolling();
                        const resultArry = resDataArry.map(item => {
                            // const id = `${item.gmtDate}-${item.gmtTime}-${item.exchange}-${item.size}-${item.price}`;
                            const newItem = utils.extend(true, item, {
                                dateTime: `${item.date}` + ' ' + `${item.time}`,
                                id: utils.guid(),
                                size: +item.size
                            });
                            newItem.price = +newItem.price;
                            return newItem;
                        });
                        this.params.sd = resDataArry[0].gmtDate;
                        this.params.st = this.getStartTime(
                            resDataArry[0].gmtDate,
                            resDataArry[0].gmtTime
                        );

                        this.dataModel.results = [
                            ...resultArry,
                            ...this.dataModel.results
                        ];
                        if (this.totalSize > this.count) {
                            this.dataModel.results.splice(
                                this.count,
                                this.totalSize - this.count
                            );
                        }
                    }
                    this.showLoading = false;
                    if (!isLooping) {
                        this.renderCompleted = true;
                    }
                })
                .catch(e => {
                    if (
                        !this.dataModel.results ||
                        !this.dataModel.results.length
                    ) {
                        this._setError(this.ERROR_TYPES.INVALID_INVESTMENT);
                    }
                });
        },
        _startPolling() {
            this._stopPolling();
            this.params.updateLastAccessTime = this.settings.updateLastAccessTime;
            this.pollingTimeout = window.setInterval(() => {
                this.getTimeSalesData(true);
            }, this.pollingTime);
        },
        _stopPolling() {
            if (this.pollingTimeout !== null) {
                window.clearInterval(this.pollingTimeout);
                this.pollingTimeout = null;
            }
        },
        getStartTime(date, time) {
            const dateTime =
                date.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') + ' ' + time;
            const secondsTime = parseFloat(new Date(dateTime).getTime()) + 1;
            const milliSeconds = new Date(secondsTime).getMilliseconds();
            const startTime = new Date(secondsTime).toLocaleTimeString('en-GB');
            return startTime + '.' + milliSeconds;
        },
        toggleExport(target, sheetName) {
            this.$refs.UI.toggleExport(target, sheetName);
        },
        async exportClick(item, formatter, exportData) {
            const columnsArry = item.columnLabel.split(',');
            const dataRows = exportData.map(row => {
                return columnsArry.map(col =>
                    this.dataFormat({ row, col, formatter })
                );
            });

            let dataStr = '';
            dataRows.forEach(row => {
                dataStr += row.join('|') + '||';
            });
            item.data = dataStr;
            const resData = await this.mktdata
                .exportWatchlist(item)
                .catch(() => '');
            utils.exportToFile(resData, item.sheetName);
        },
        dataFormat({ row, col, formatter }) {
            const column = dataPoints.find(dp => dp.id === col) || {};
            const obj = {
                value: row[col],
                dataType: column.dataType,
                formatter
            };
            return utils.getFormatValue(obj);
        },
        _setError(err) {
            this.error = err;
            this.dataModel = {
                security: (this.params || {}).security,
                results: []
            };
            this.showLoading = false;
        },
        changeType({ symbol, count, pollingTime }) {
            this.count = count;
            this.pollingTime = pollingTime;
            this.params = {
                security: symbol,
                count
            };
            if (this.initialized) {
                this._startPolling();
            }
        },
        changeFilter(param) {
            this.$emit('filter-change', param);
        },
        hideExportPopover() {
            this.$emit('export-popover-hide');
        }
    },
    components: {
        TIMESALESUI
    }
};
</script>
